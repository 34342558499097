<template>
    <section class="orderconfirmation">
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <h3 class="h3 border-divider mb-4">
            {{ t(isConfirmingOrder ? orderConfirmationTitle : 'checkout_OrderDetails') }}
        </h3>
        <div v-if="order">
            <b-row v-if="!isConfirmingPreOrder" class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('checkout_OrderNumber') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.orderNumber }}</span></b-col
                >
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{
                        t(isConfirmingPreOrder ? 'checkout_PreOrderDate' : 'checkout_OrderDate')
                    }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.orderDate | date }}</span></b-col
                >
            </b-row>
            <b-row v-if="isConfirmingPreOrder" class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('expirationDate') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.expirationDate | date }}</span></b-col
                >
            </b-row>
            <b-row class="mb-3">
                <b-col sm="6" md="4" xl="3">
                    <span>{{ t('checkout_Customer') }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.customer }}</span>
                    <span v-if="order.ocosCustomerId" class="text-muted">
                        ({{ order.ocosCustomerId }})</span
                    >
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col sm="6" md="4" xl="3">
                    <span>{{
                        t(isConfirmingPreOrder ? 'checkout_PreOrderStatus' : 'statusNormalCase')
                    }}</span>
                </b-col>
                <b-col sm="6" md="8" xl="9">
                    <span class="font-weight-bold">{{ order.orderStatus }}</span>
                </b-col>
            </b-row>
            <b-row v-if="get(order, 'shippingDetails.deliveryInstructions')" class="mb-3">
                <b-col sm="12">
                    <span class="font-weight-bold">{{ t('checkout_DeliveryInstructions') }}</span>
                </b-col>
                <b-col sm="12">
                    <span>{{ get(order, 'shippingDetails.deliveryInstructions', '') }}</span></b-col
                >
            </b-row>
            <b-row v-if="get(order, 'billingDetails.billingInstructions')" class="mb-3">
                <b-col sm="12">
                    <span class="font-weight-bold">{{ t('checkout_BillingInstructions') }}</span>
                </b-col>
                <b-col sm="12">
                    <span>{{ get(order, 'billingDetails.billingInstructions', '') }}</span></b-col
                >
            </b-row>
            <b-row v-if="get(order, 'paymentDetails.paymentDetailsNote')" class="mb-5">
                <b-col sm="12">
                    <span class="font-weight-bold"> {{ t('checkout_PaymentNotes') }}</span>
                </b-col>
                <b-col sm="12">
                    <span>{{ get(order, 'paymentDetails.paymentDetailsNote', '') }}</span></b-col
                >
            </b-row>
            <b-row v-if="!isConfirmingPreOrder" class="mb-3">
                <b-col md="12">
                    <span class="d-block mb-1 font-weight-bold">
                        {{ t('checkout_ShippingAddress') }}
                    </span>
                    <span class="d-block mb-1">{{ shippingDetailsAddress }}</span>
                    <div v-if="this.currentZone === ZoneCodes.US">
                        <span class="d-block mb-1"
                            >{{ get(order, 'shippingDetails.city', '') }}
                            {{ get(order, 'shippingDetails.state', '') }}
                            {{ get(order, 'shippingDetails.zip', '') }}</span
                        >
                        <span class="d-block mb-1">{{
                            get(order, 'shippingDetails.country', '')
                        }}</span>
                    </div>
                    <div v-if="this.currentZone === ZoneCodes.OUS">
                        <span class="d-block mb-1"
                            >{{ get(order, 'shippingDetails.city', '') }}
                            {{ get(order, 'shippingDetails.country', '') }}</span
                        >
                        <span class="d-block mb-1">{{
                            get(order, 'shippingDetails.zip', '')
                        }}</span>
                    </div>
                    <span class="d-block mb-1">{{
                        get(order, 'shippingDetails.phoneNumber', '')
                    }}</span>
                    <span v-if="get(order, 'shippingDetails.email')" class="d-block pb-3"
                        ><a :href="`mailto:${get(order, 'shippingDetails.email', '')}`">{{
                            get(order, 'shippingDetails.email', '')
                        }}</a></span
                    >
                </b-col>
            </b-row>
            <b-row v-if="!isConfirmingPreOrder" class="mb-5">
                <b-col md="6">
                    <span class="d-block mb-1 font-weight-bold">
                        {{ t('checkout_BillingAddress') }}
                    </span>
                    <span class="d-block mb-1">{{ get(order, 'billingDetails.address', '') }}</span>
                    <div v-if="this.currentZone === ZoneCodes.US">
                        <span class="d-block mb-1"
                            >{{ get(order, 'billingDetails.city', '') }}
                            {{ get(order, 'billingDetails.state', '') }}
                            {{ get(order, 'billingDetails.zip', '') }}</span
                        >
                        <span class="d-block mb-1">{{
                            get(order, 'billingDetails.country', '')
                        }}</span>
                    </div>
                    <div v-if="this.currentZone === ZoneCodes.OUS">
                        <span class="d-block mb-1"
                            >{{ get(order, 'billingDetails.city', '') }}
                            {{ get(order, 'billingDetails.country', '') }}</span
                        >
                        <span class="d-block mb-1">{{ get(order, 'billingDetails.zip', '') }}</span>
                    </div>
                    <span class="d-block mb-1">{{
                        get(order, 'billingDetails.phoneNumber', '')
                    }}</span>
                    <span v-if="get(order, 'billingDetails.email')" class="d-block pb-3"
                        ><a :href="`mailto:${get(order, 'billingDetails.email', '')}`">{{
                            get(order, 'billingDetails.email', '')
                        }}</a></span
                    >
                </b-col>
                <b-col md="6">
                    <span class="d-block mb-2 font-weight-bold">
                        {{ t('checkout_PaymentMethod') }}
                    </span>
                    <span class="d-block mb-1">{{ t('checkout_PurchaseNumber') }}:</span>
                    <span class="d-block font-weight-bold">{{
                        get(order, 'paymentDetails.purchaseOrderNumber', '')
                    }}</span>
                </b-col>
            </b-row>
            <div class="border-divider pb-5">
                <div v-if="isConfirmingPreOrder">
                    <div
                        class="h4"
                        v-html="t('cancelPreOrderNote', {customerName: activeCustomer.name})"
                    ></div>
                    <br />
                </div>
                <h4 class="h4 mb-2 border-divider font-weight-bold">
                    {{
                        t(
                            isConfirmingPreOrder
                                ? 'reservationDetails_LensInPreOrder'
                                : 'checkout_ItemInOrder'
                        )
                    }}
                </h4>
                <div v-for="lensGroup in lensGroups" :key="lensGroup.patientId" class="lens-group">
                    <h5 class="text-center my-3">
                        {{ t('checkout_Patient') }}:
                        <router-link
                            :to="{
                                name: 'PatientDetails',
                                params: {
                                    patientId: lensGroup.patientId,
                                },
                            }"
                            class="text-primary font-weight-bold"
                        >
                            <u>{{ lensGroup.patientOcosId }}</u>
                        </router-link>
                    </h5>
                    <LensCard
                        v-for="lens in lensGroup.items"
                        :key="lens.orderItemId"
                        :lens="lens"
                        :readonly="true"
                        :showPrimaryLensStatus="activeCustomer.maxLenses > 1"
                        :additionalLensStatusKeyText="`checkout_AdditionalLensStatus_${currentZone}`"
                        :is-confirm-buttton-disabled="!returnReasonId"
                        remove-prompt-key-text="orderConfirmation_ReturnLensPrompt"
                        @on-remove="returnLens"
                    >
                        <template
                            v-if="!lens.isMto && !isConfirmingPreOrder"
                            v-slot:custom-actions-before
                        >
                            <div class="custom-actions text-center">
                                <b-button
                                    @click="iodDownload(lens)"
                                    variant="outline-primary"
                                    class="mt-1"
                                >
                                    {{ isSidButton(lens) }}
                                </b-button>
                            </div>
                        </template>
                        <template
                            v-if="hasOrderingPermission && !isConfirmingPreOrder && false"
                            v-slot:custom-actions-after="{props}"
                        >
                            <h6 v-if="lens.returnRequested" class="mt-4 text-center min-w-106px">
                                {{ t('returnRequested') }} <br />
                                {{ lens.returnReason }} <br />
                                {{ lens.returnRequested | date }}
                            </h6>
                            <div v-else class="custom-actions text-center min-w-106px">
                                <img
                                    class="pointer"
                                    width="40"
                                    height="40"
                                    src="@/assets/shopping-cart/back-box.svg"
                                    @click="props.showRemovePrompt = true"
                                />
                            </div>
                        </template>
                        <template v-slot:remove-prompt-after-card="{props}">
                            <b-card class="mt-3">
                                <b-form-group
                                    :label="t('selectReason')"
                                    label-align="left"
                                    label-size="lg"
                                >
                                    <b-form-select
                                        :options="returnReasons"
                                        v-model="returnReasonId"
                                        text-field="reason"
                                        value-field="returnReasonId"
                                        size="sm"
                                        @change="onSelectReason($event, props)"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null">{{
                                                t('select')
                                            }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-card>
                        </template>
                    </LensCard>
                </div>
                <template v-if="!isConfirmingPreOrder">
                    <AccesoryCard
                        v-for="accessory in accessories"
                        :key="accessory.orderItemId"
                        :accessory="accessory"
                        :readonly="true"
                    />
                </template>
            </div>
            <b-row class="mt-5 mb-5 pb-5 text-center text-sm-left">
                <b-col
                    sm="6"
                    class="align-self-center"
                    v-if="hasOrderingPermission && !isDistributorSurgeonMode"
                >
                    <span class="h4 mr-2">{{ t('checkout_OrderTotal') }}</span>
                    <span class="h3 font-weight-bold">{{ orderTotal | toCurrency }}</span></b-col
                >
                <b-col sm="6">
                    <b-button
                        v-show="false"
                        @click="notImplemented"
                        size="lg"
                        variant="primary"
                        class="btn-block-xs-only float-right"
                    >
                        {{ t('checkout_TrackPackage') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-row v-if="!isDistributorSurgeonMode">
                <h5 class="mt-1 mb-1 font-weight-bold" v-html="t('orderDisclaimer')"></h5>
            </b-row>
        </div>
        <b-overlay :show="showComplaintReasonMsg" no-wrap fixed>
            <template #overlay>
                <b-card footer-class="text-center">
                    <h3 class="font-weight-bold text-center">NOTICE</h3>
                    <p>
                        Complaints are not a valid return reason for this system. Please contact
                        Customer Support at
                        <a :href="'tel:' + customerServicePhone">{{ customerServicePhone }}</a> or
                        send an email to
                        <a :href="'mailto:' + customerServiceEmail">{{ customerServiceEmail }}</a>
                        with details of your complaint.
                    </p>
                    <div class="text-center">
                        <b-button variant="primary" @click="showComplaintReasonMsg = false">
                            {{ t('close') }}
                        </b-button>
                    </div>
                </b-card>
            </template>
        </b-overlay>
    </section>
</template>

<script>
import {mapMutations, mapGetters, mapActions, mapState} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import get from 'lodash/get';
import LensCard from '@/components/LensCard.vue';
import AccesoryCard from '@/components/AccesoryCard.vue';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import {ITEM_TYPES} from '@/store/modules/cart';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'OrderConfirmation',
    components: {LensCard, AccesoryCard, IodReportPopup},
    props: {
        orderId: {
            type: [Number, String],
            default: null,
        },
        defaultOrder: {
            type: Object,
            default: () => null,
        },
        isConfirmingOrder: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showComplaintReasonMsg: false,
            ZoneCodes,
            modalShow: false,
            lensOrderId: null,
            returnReasonId: null,
            returnReasons: [],
            customerServiceEmail: 'email-goes@here.com',
            customerServicePhone: 'phone-goes-here',
            order: this.defaultOrder,
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomer']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['currentUser']),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        isConfirmingPreOrder() {
            return this.isConfirmingOrder && this.isDistributorSurgeonMode;
        },
        orderConfirmationTitle() {
            return this.isDistributorSurgeonMode
                ? 'checkout_PreOrderConfirmation'
                : 'checkout_OrderConfirmation';
        },
        thankYouText() {
            return this.isDistributorSurgeonMode
                ? 'checkout_ThankYouPreOrder'
                : 'checkout_ThankYou';
        },
        lensGroups() {
            return get(this.order, 'lensItemsByPatientId', []);
        },
        accessories() {
            return get(this.order, 'items', [])
                .filter((item) => item.itemType === ITEM_TYPES.ACCESSORY)
                .map((item) => ({
                    ...item,
                    quantityWrapper: item.quantity.toLocaleString('en-US'),
                }))
                .sort((a, b) => b.automaticallyAdded - a.automaticallyAdded);
        },
        shippingDetailsAddress() {
            const address = get(this.order, 'shippingDetails.address');
            try {
                const addressArray = JSON.parse(address);
                return addressArray.join(', ');
            } catch (e) {
                return address;
            }
        },
        orderTotal() {
            return get(this.order, 'items', []).reduce(
                (acc, item) => acc + item.price * item.quantity,
                0
            );
        },
        orderSummary() {
            var frieghtCharge = get(this.order, 'shippingDetails.fixedFreightCharge', 0);
            if (frieghtCharge == null) {
                frieghtCharge = 0;
            }

            return {
                isCartSummary: false,
                orderTotal: this.orderTotal,
                itemsCount: get(this.order, 'items', []).reduce(
                    (acc, item) => acc + item.quantity,
                    0
                ),
                limit: get(this.order, 'creditLimit', 0),
                balance: get(this.order, 'creditBalance', 0),
                fixedFreightCharge: frieghtCharge, //get(this.order, 'shippingDetails.fixedFreightCharge', 0),
            };
        },
    },
    methods: {
        get,
        ...mapMutations('cart', ['setSummary']),
        ...mapActions('orders', ['getReturnReasons']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        iodDownload(lens) {
            this.lensOrderId = lens.orderItemId.toString();
            this.modalShow = true;
        },
        /**
         * Determines if the lens is a Spheric or Toric for the IOD/SID button
         * in this case, look for either a VICM or VTICM in the model string
         *
         * @param {Object} lensDetails - the lens details
         * returns button text for Sperhic or Toric
         */
        isSidButton(lensDetail) {
            return lensDetail.toricInventoryId ? 'IOD' : 'SID';
        },
        onSelectReason(returnReasonId, props) {
            if (returnReasonId !== -1) return;
            props.showRemovePrompt = false;
            this.showComplaintReasonMsg = true;
            this.returnReasonId = null;
        },
        async getResources() {
            this.returnReasons = (await this.getReturnReasons()).results || [];
        },
        async fetchOrder() {
            try {
                const {success, results} = await this.blockingRequest('orders/fetch', this.orderId);
                if (success) {
                    this.order = results;
                }
            } catch (error) {
                this.$router.push({
                    name: 'NotFound',
                });
            }
        },
        async returnLens({orderItemId}) {
            try {
                this.setSpinner(true);
                const {success, error} = (
                    await this.$http.post(`orders/${this.orderId}/items/${orderItemId}`, {
                        returnReasonId: this.returnReasonId,
                    })
                ).data;
                if (!success) throw error;
                await this.fetchOrder();
                this.returnReasonId = null;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            } finally {
                this.setSpinner(false);
            }
        },
    },
    destroyed() {
        this.setSummary(null);
        this.$emit('update:page-title', this.t('checkout_ShoppingCart'));
    },
    beforeMount() {
        this.$emit('update:page-title', this.isConfirmingOrder ? this.t(this.thankYouText) : null);
    },
    async created() {
        if (this.orderId && !this.defaultOrder) {
            if (isNaN(this.orderId)) {
                this.$router.push({
                    name: 'NotFound',
                });
                return;
            }
            await this.fetchOrder();
        } else if (!this.orderId && !this.defaultOrder) {
            // In this scenario the user is getting back to the order confirmation page by the browser back button
            // When is a pre order we don't have orderId or default order as this is a stateless component.
            this.$router.push({
                name: this.isDistributorSurgeonMode ? 'PreOrderList' : 'OrderList',
            });
            return;
        }
        this.setSummary(this.orderSummary);
        this.getResources();

        //get the zone details for the current zone
        let zoneDetails = this.currentUser.zoneInfo.find((zone) => {
            return zone.code == this.currentZone;
        });
        if (zoneDetails) {
            this.customerServiceEmail = zoneDetails.customerServiceEmail;
            this.customerServicePhone = zoneDetails.customerServicePhone;
        }
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/variables';

.border-divider {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}
.min-w-106px {
    min-width: 106px;
}
.lens-group {
    border-bottom: 3px solid $gray-dark;
    &:last-child {
        border-bottom: 0px;
    }
}
</style>
